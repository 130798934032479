<template>
  <div class="w-full pt-5">
    <div class="flex w-full">
      <div class="border-r border-romanSilver h-6 flex pt-1">
        <BackButton
          label="Back"
          @onClick="$router.back()"
          variant="secondary"
          class="ml-2"
        />
      </div>
      <h1 class="text-xl text-left font-extrabold pl-4 mr-8">
        Performance Reports
      </h1>
      <Breadcrumb :items="breadcrumbs" />
    </div>
    <template>
      <div class="w-full p-5 flex mt-2 flex-col">
        <div class="w-full flex flex-col">
          <div class="w-full pt-5">
            <template v-if="isLoading">
              <div class="mt-20 h-full">
                <Loader size="xxs" :loader-image="true" />
              </div>
            </template>

            <template v-else>
              <div
                class="flex flex-col justify-center items-center gap-5 px-10 py-10 mt-10"
                v-if="!statusReport"
              >
                <Icon
                  icon-name="comreview_illustration"
                  size=""
                  class-name="w-56 h-56"
                />
                <p
                  class="font-normal text-base leading-5 text-jet text-center w-96"
                >
                  Performance Report not available.
                </p>
              </div>

              <template v-else>
                <template>
                  <div class="flex justify-between items-center -mt-1">
                    <span
                      class="font-bold text-lg mb-5 mx-3 leading-5 tracking-normal capitalize text-darkPurple"
                    >
                      Performance Status Reports
                    </span>
                  </div>

                  <Card class="px-5 py-2 flex flex-col">
                    <div class="flex justify-between items-center m-1">
                      <div
                        class="flex gap-2 mb-2 font-bold text-sm leading-6 tracking-normal"
                      >
                        <span class="uppercase text-romanSilver"
                          >Employee:</span
                        >
                        <span class="capitalize text-darkPurple">
                          {{ employeeData.fname }}
                          {{ employeeData.lname }}
                        </span>
                      </div>
                    </div>
                    <div class="flex gap-5">
                      <div class="flex-1 w-2/12 rounded-md">
                        <img
                          v-if="
                            employeeData.photo !== '' &&
                            employeeData.photo !== null
                          "
                          :src="employeeData.photo"
                          alt="profile"
                          style="
                            height: 183px;
                            width: 183px;
                            border-radius: 5px;
                          "
                        />
                        <div
                          style="
                            height: 183px;
                            width: 183px;
                            border-radius: 5px;
                          "
                          class="text-blueCrayola border text-center font-black text-lg pt-14 mr-2"
                          v-else
                        >
                          {{
                            $getInitials(
                              `${employeeData.fname} ${employeeData.lname}`
                            )
                          }}
                        </div>
                      </div>
                      <div class="flex flex-col flex-3 w-10/12">
                        <div class="flex mb-2 w-full gap-2">
                          <div
                            class="flex flex-1 p-3 shadow rounded-md gap-2 items-start"
                          >
                            <Icon
                              class-name="text-romanSilver"
                              size="xs"
                              icon-name="calendar"
                            />
                            <div class="flex flex-col tracking-wide font-bold">
                              <span class="text-xs text-carrotOrange uppercase"
                                >Designation</span
                              >
                              <span class="text-sm text-jet uppercase">{{
                                employeeData.designation
                              }}</span>
                            </div>
                          </div>
                          <div
                            class="flex flex-1 p-3 shadow rounded-md gap-2 items-start"
                          >
                            <Icon
                              class-name="text-romanSilver"
                              size="xs"
                              icon-name="icon-department"
                            />
                            <div class="flex flex-col tracking-wide font-bold">
                              <span class="text-xs text-blueCrayola uppercase"
                                >Function</span
                              >
                              <span class="text-sm text-jet uppercase">{{
                                employeeData.function
                              }}</span>
                            </div>
                          </div>
                          <div
                            class="flex flex-1 p-3 shadow rounded-md gap-2 items-start"
                          >
                            <Icon
                              class-name="text-romanSilver"
                              size="xs"
                              icon-name="user"
                            />
                            <div class="flex flex-col tracking-wide font-bold">
                              <span
                                class="text-xs text-mediumSeaGreen uppercase"
                                >Line Manager</span
                              >
                              <span
                                class="text-sm text-jet uppercase whitespace-nowrap"
                                >{{ employeeData.lineManager }}</span
                              >
                            </div>
                          </div>
                        </div>
                        <div class="flex mb-4 w-full gap-2">
                          <div
                            class="flex flex-1 p-3 shadow rounded-md gap-2 items-start w-1/3"
                          >
                            <Icon
                              class-name="text-romanSilver"
                              size="xs"
                              icon-name="icon-user-list"
                            />
                            <div class="flex flex-col tracking-wide font-bold">
                              <span class="text-xs text-lite uppercase"
                                >Employee ID</span
                              >
                              <span class="text-sm text-jet uppercase">{{
                                employeeData.employeeId
                              }}</span>
                            </div>
                          </div>
                          <div
                            class="flex flex-2 p-3 shadow rounded-md gap-2 items-start w-2/3"
                          >
                            <Icon
                              class-name="text-romanSilver"
                              size="xs"
                              icon-name="pin"
                            />
                            <div class="flex flex-col tracking-wide font-bold">
                              <span class="text-xs text-flame uppercase"
                                >Office Location</span
                              >
                              <span class="text-sm text-jet uppercase">{{
                                employeeData.location
                              }}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="my-2 flex justify-between gap-3">
                      <Card class="w-full">
                        <div
                          class="flex justify-between items-center px-3 py-2 uppercase"
                        >
                          <p class="text-jet font-semibold text-sm leading-5">
                            kpi count
                          </p>
                          <p
                            class="text-blueCrayola font-semibold text-sm leading-5"
                          >
                            {{ kpiCount }}
                          </p>
                        </div>
                        <div
                          class="flex justify-between items-center px-3 py-2 uppercase"
                        >
                          <p class="text-jet font-semibold text-sm leading-5">
                            reported
                          </p>
                          <p
                            class="text-blueCrayola font-semibold text-sm leading-5"
                          >
                            {{ reported }}
                          </p>
                        </div>
                      </Card>
                      <Card class="w-full">
                        <div
                          class="flex justify-between items-center px-3 py-2 uppercase"
                        >
                          <p class="text-jet font-semibold text-sm leading-5">
                            Completed Rate (number)
                          </p>
                          <p
                            class="text-blueCrayola font-semibold text-sm leading-5"
                          >
                            {{ completedRate }} of {{ kpiCount }}
                          </p>
                        </div>
                        <div
                          class="flex justify-between items-center px-3 py-2 uppercase"
                        >
                          <p class="text-jet font-semibold text-sm leading-5">
                            Completed Rate (Weight)
                          </p>
                          <p
                            class="text-blueCrayola font-semibold text-sm leading-5"
                          >
                            {{ completedWeight }}%
                          </p>
                        </div>
                      </Card>
                    </div>
                  </Card>
                </template>
                <template v-if="reports.length > 0">
                  <div class="mt-10">
                    <Table
                      :headers="header"
                      :items="reports"
                      :has-checkbox="false"
                      :has-number="false"
                    >
                      <template v-slot:item="{ item }">
                        <div
                          v-if="item.kpa"
                          class="font-semibold text-sm leading-5 tracking-widest"
                        >
                          <span class="text-darkPurple">{{
                            item.data.kpa
                          }}</span>
                        </div>
                        <div
                          v-if="item.kpi"
                          class="font-semibold text-sm leading-5 tracking-widest"
                        >
                          <span class="text-darkPurple">{{
                            item.data.kpi
                          }}</span>
                        </div>
                        <div
                          v-if="item.criteria"
                          class="font-semibold text-sm leading-5 tracking-widest"
                        >
                          <span class="text-darkPurple">{{
                            item.data.criteria
                          }}</span>
                        </div>
                        <div
                          v-if="item.targetDate"
                          class="font-semibold text-sm leading-5 tracking-widest"
                        >
                          <span class="text-darkPurple">{{
                            item.data.targetDate
                          }}</span>
                        </div>
                        <div
                          v-if="item.achievement"
                          class="font-semibold text-sm leading-5 tracking-widest"
                        >
                          <span class="text-darkPurple">{{
                            item.data.achievement
                          }}</span>
                        </div>
                        <div
                          v-if="item.support"
                          class="font-semibold text-sm leading-5 tracking-widest"
                        >
                          <span class="text-darkPurple">{{
                            item.data.support
                          }}</span>
                        </div>
                        <div v-if="item.comments">
                          <span class="text-darkPurple">{{
                            item.data.comments
                          }}</span>
                        </div>
                        <div v-if="item.status">
                          <Badge
                            :label="item.data.status"
                            variant="primary"
                            :background-color="
                              item.data.status === 'completed'
                                ? 'rgba(19, 181, 106, 0.15)'
                                : 'rgba(135, 142, 153, 0.15)'
                            "
                            :color="
                              item.data.status === 'completed'
                                ? '#13B56A'
                                : '#878E99'
                            "
                            class="capitalize"
                            style="
                              margin-right: 12px;
                              font-size: 14px;
                              padding: 12px 10px;
                              height: 35px;
                              border-radius: 5px;
                            "
                          />
                        </div>
                        <div v-if="item.id" class="cursor-pointer"></div>
                      </template>
                    </Table>
                  </div>
                </template>
                <template v-else>
                  <div
                    class="flex flex-col justify-center items-center gap-5 px-10 py-10"
                  >
                    <Icon
                      icon-name="comreview_illustration"
                      size=""
                      class-name="w-56 h-56"
                    />
                    <p
                      class="font-normal text-base leading-5 text-jet text-center w-96"
                    >
                      No performance report added.
                    </p>
                  </div>
                </template>
              </template>
              <template>
                <ValidationObserver rules="required" v-slot="{ handleSubmit }">
                  <RightSideBar
                    v-if="isOpenNewReport"
                    @submit="handleSubmit(submit)"
                    @close="isOpenNewReport = false"
                    button-class="bg-dynamicBackBtn text-white"
                  >
                    <template v-slot:title>
                      <p
                        class="text-darkPurple font-bold text-lg leading-5 p-0"
                      >
                        Report New
                      </p>
                    </template>
                    <template>
                      <div
                        class="mb-10 flex flex-col justify-start items-start gap-5"
                      >
                        <Select
                          class="w-full text-sm font-normal leading-5 text-darkPurple"
                          placeholder="--Select--"
                          label="KPA"
                          :options="kpaTypeOptions"
                          :rules="['required']"
                          @input="selectedType($event)"
                          v-model="payload.kpaTypeId"
                        />
                        <Select
                          class="w-full text-sm font-normal leading-5 text-darkPurple"
                          placeholder="--Select--"
                          label="KPI"
                          :options="kpiOptions"
                          :rules="['required']"
                          @input="selectedKPI($event)"
                          v-model="payload.kpiId"
                        />
                        <div
                          class="flex flex-col justify-start item-start gap-1 w-full"
                        >
                          <label for="comment" class="text-sm">Criteria</label>
                          <Textarea
                            class="w-full text-sm font-normal leading-5 text-darkPurple"
                            label="Criteria"
                            placeholder="Auto Calculated"
                            :disabled="true"
                            :height="130"
                            v-model="payload.criteria"
                          />
                        </div>
                        <TextInput
                          class="w-full text-sm font-normal leading-5 text-darkPurple"
                          label="Weight"
                          placeholder="Auto Calculated"
                          :disabled="true"
                          v-model="weight"
                        />
                        <div class="w-full">
                          <div class="text-sm text-jet">Target Date</div>
                          <div class="date-flex mt-1">
                            <datepicker
                              placeholder="--Select Day & Month & Year--"
                              style="width: 100%; outline: none"
                              input-class="date-input"
                              v-model="targetDate"
                              :disabled="true"
                            />
                          </div>
                        </div>
                        <Select
                          class="w-full text-sm font-normal leading-5 text-darkPurple"
                          placeholder="--Select--"
                          label="Support Required?"
                          :options="supportOptions"
                          :rules="['required']"
                          v-model="payload.requireSupport"
                        />
                        <div
                          class="flex flex-col justify-start item-start gap-1 w-full"
                        >
                          <label for="comment" class="text-sm">Comment</label>
                          <Textarea
                            class="w-full text-sm font-normal leading-5 text-darkPurple"
                            placeholder="Comment"
                            id="comment"
                            :height="96"
                            :rules="['required']"
                            v-model="payload.comment"
                          />
                        </div>
                        <TextInput
                          class="w-full text-sm font-normal leading-5 text-darkPurple"
                          label="Current achievement"
                          placeholder="Enter"
                          :rules="['required']"
                          v-model="payload.currentAchievement"
                        />
                        <Select
                          class="w-full text-sm font-normal leading-5 text-darkPurple"
                          placeholder="--Select--"
                          label="Status"
                          :options="optionsStatus"
                          :rules="['required']"
                          v-model="payload.status"
                        />
                      </div>
                    </template>
                  </RightSideBar>
                </ValidationObserver>
              </template>
            </template>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { ValidationObserver } from "vee-validate";
import BackButton from "@scelloo/cloudenly-ui/src/components/back-button";
import Breadcrumb from "@scelloo/cloudenly-ui/src/components/bread-crumb";
import Datepicker from "vuejs-datepicker";
import Select from "@scelloo/cloudenly-ui/src/components/select";
import Textarea from "@scelloo/cloudenly-ui/src/components/text-area";
import Badge from "@scelloo/cloudenly-ui/src/components/badge";
import Table from "@scelloo/cloudenly-ui/src/components/table";
import Card from "@scelloo/cloudenly-ui/src/components/card";
import TextInput from "@scelloo/cloudenly-ui/src/components/text";
import Loader from "@/components/Loader";
import RightSideBar from "@/components/RightSideBar";

export default {
  name: "EssPerformanceAppraisals",
  components: {
    Breadcrumb,
    BackButton,
    ValidationObserver,
    Badge,
    Select,
    Textarea,
    TextInput,
    Datepicker,
    RightSideBar,
    Loader,
    Card,
    Table,
  },
  data() {
    return {
      breadcrumbs: [
        {
          disabled: false,
          text: "Performance",
          href: "Performance",
          id: "Performance",
        },
        {
          disabled: false,
          text: "Performance Reports",
          href: "performance-reports",
          id: "PerformanceReports",
        },
      ],
      openModal: false,
      isLoading: true,
      isOpenNewReport: false,
      statusReport: false,
      paginationList: {
        page: 1,
        lastPage: 1,
        total: 1,
        from: 1,
        to: 1,
      },
      optionsStatus: [
        { id: "completed", name: "Completed" },
        { id: "work_in_progress", name: "Work-in-Progress" },
        { id: "on_hold", name: "On-Hold" },
        { id: "cancelled", name: "Cancelled" },
      ],
      header: [
        { title: "KPA", value: "kpa" },
        { title: "KPI", value: "kpi" },
        { title: "criteria", value: "criteria" },
        { title: "target date", value: "targetDate" },
        { title: "current achievement", value: "achievement" },
        { title: "support required?", value: "support" },
        { title: "comments", value: "comments" },
        { title: "status", value: "status" },
        { title: "", value: "id", image: true },
      ],
      employeeData: {
        photo: "",
        designation: "",
        function: "",
        lineManager: "",
        employeeId: "",
        location: "",
        fname: "",
        lname: "",
        userId: "",
      },
      kpiCount: "",
      reported: "",
      completedRate: "",
      completedWeight: "",
      reports: [],
      kpaTypeOptions: [],
      kpaOptions: [],
      agreementKPA: [],
      kpiOptions: [],
      weight: "",
      targetDate: "",
      supportOptions: [
        {
          id: "1",
          name: "Yes",
        },
        {
          id: "2",
          name: "No",
        },
      ],
      payload: {
        employeeAgreementId: "",
        orgId: "",
        kpiId: "",
        kpaTypeId: "",
        status: "",
        criteria: "",
        currentAchievement: "",
        requireSupport: "",
        comment: "",
        employeeId: "",
      },
    };
  },

  methods: {
    getStatusReports() {
      this.$_getStatusReportEmployee(
        this.$route.params.year,
        this.$route.params.id
      )
        .then((response) => {
          this.statusReport = true;
          const employeeData = response.data.data.employee;

          this.employeeData.photo = employeeData.photo;
          this.employeeData.designation = employeeData.designation;
          this.employeeData.function = employeeData.function;
          this.employeeData.lineManager = employeeData.lineManager
            ? `${employeeData.lineManager.fname} ${employeeData.lineManager.lname}`
            : "---";
          this.employeeData.employeeId = "---";
          this.employeeData.location = employeeData.location;
          this.employeeData.fname = employeeData.fname;
          this.employeeData.lname = employeeData.lname;
          this.employeeData.userId = employeeData.userId;

          this.kpiCount = response.data.data.kpiCount;
          this.reported = response.data.data.reported;
          this.completedRate = response.data.data.completedRate;
          this.completedWeight = response.data.data.completedWeight;

          const reportArr = response.data.data.reports;

          reportArr.map((reports) => {
            this.reports.push({
              kpa: reports.kpa_type.name,
              kpi: reports.kpa.name,
              criteria: reports.criteria.replace(/\n/g, " "),
              targetDate: this.$DATEFORMAT(
                new Date(this.resetDate(reports.targetDate)),
                "MMMM dd yyyy"
              ),
              achievement: reports.currentAchievement,
              support: reports.requireSupport ? "Yes" : "No",
              comments: reports.comment,
              status: reports.status,
              id: reports.id,
            });
            return {};
          });

          this.isLoading = false;
        })
        .catch(() => {
          this.isLoading = false;
        });
    },

    selectedType(value) {
      this.kpiOptions = [];

      this.agreementKPA.map((kpaDetails) => {
        if (kpaDetails.kpa_type.id === value) {
          this.kpiOptions.push({
            id: kpaDetails.id,
            name: kpaDetails.name,
          });
        }
        return {};
      });
    },

    selectedKPI(value) {
      const kpisArr = [];

      this.agreementKPA.map((kpaDetails) => {
        if (kpaDetails.id === value) {
          kpaDetails.kpis_employee.map((kpis) => {
            kpisArr.push(`${kpis.criteria}\n \n`);

            this.payload.criteria = kpisArr.toString().replace(/,/g, "");

            return {};
          });

          this.weight = kpaDetails.weight;
          this.targetDate = this.resetDate(kpaDetails.targetDate);
        }
        return {};
      });
    },

    resetDate(value) {
      if (value) {
        const dateValue = value;

        const split = dateValue.split("T00:00:00.000Z");

        return `${split[0]}T13:00:00.000Z`;
      }
      return "";
    },

    getAgreements() {
      this.$_getEmployeeAgreement(this.$route.params.id).then((response) => {
        const myAgreements = response.data.EmployeePerformanceAgreements;

        myAgreements.map((agreement) => {
          if (
            Number(agreement.year) === Number(this.$route.params.year) &&
            agreement.approvedByAdmin
          ) {
            this.payload.employeeAgreementId = agreement.id;
            this.payload.orgId = this.$orgId;

            const agreementKPA = agreement.employee_agreement_kpa;

            this.agreementKPA = agreementKPA;

            agreementKPA.map((kpaDetails) => {
              if (!this.kpaOptions.includes(kpaDetails.kpa_type.id)) {
                this.kpaTypeOptions.push(kpaDetails.kpa_type);
              }

              this.kpaOptions.push(kpaDetails.kpa_type.id);

              return {};
            });
          }

          return {};
        });
      });
    },

    submit() {
      if (this.payload.requireSupport === "1") {
        this.payload.requireSupport = true;
      }

      if (this.payload.requireSupport === "2") {
        this.payload.requireSupport = false;
      }

      this.payload.employeeId = this.$AuthUser.id;

      this.$_postStatusReport(this.payload)
        .then((response) => {
          this.$toasted.success(response.data.message, { duration: 5000 });
          this.isOpenNewReport = false;
          this.kpaTypeOptions = [];
          this.kpaOptions = [];
          this.isLoading = true;
          this.getStatusReports();
          this.getAgreements();
        })
        .catch((err) => {
          this.$toasted.error(err.response.data.message, { duration: 5000 });
        });
    },
  },

  mounted() {
    this.getStatusReports();
    this.getAgreements();
  },
};
</script>

<style>
.button_class {
  padding: 10px 40px !important;
  font-weight: 600;
  display: flex;
  justify-self: end;
}
.button-class-pimary {
  font-weight: 600 !important;
  font-size: 16px !important;
  padding: 10px 40px !important;
}
.button-class-secondary {
  color: #321c3b !important;
  background: white !important;
  font-weight: 600 !important;
  font-size: 16px !important;
  padding: 10px 40px !important;
}
</style>
